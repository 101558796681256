import { gql } from '@apollo/client'

const getUserProfileById = gql`
    query node($id:ID!){
        node(
                id:$id
            )
            {
                id,
                ... on UserDetail {
                    email,
                    firstName
                    lastName
                    profession
                    description
                    createdAt
                    updatedAt
                    avatar
                    username
                    phone
                    totalBalance
                    currentBalance
                    newNotifications
                    followerCount
                    followingCount
                    fullName
                }
            }
    }
`

export default getUserProfileById