import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect, useState, Suspense, lazy } from "react";
import "react-spring-bottom-sheet/dist/style.css";
import ReactGA from "react-ga";
import { ToastProvider } from "@components/ui/toast";
import { Toaster } from "@components/ui/toaster";
import useAuthManger from "@hooks/useAuthManger";
import PublicRoute from "@components/Route/PublicRoute";
import supabase from "@libs/supbase";
import PrivateRoute from "@components/Route/PrivateRoute";
import { ACCESS_TOKEN } from "@utils/constants";
import ContactUs from "@pages/main/ContactUs";
import suppgramLogo from "@assets/images/suppgram_low.png"; // Assuming the image is stored locally
import { TooltipProvider } from "@components/ui/tooltip";

// Lazy load components
const LoginPage = lazy(() => import("@pages/auth/LoginPage"));
const SignUpPage = lazy(() => import("@pages/auth/SignUpPage"));
const HomePage = lazy(() => import("@pages/main/HomePage"));
const DashboardPage = lazy(() => import("@pages/main/DashboardPage"));
const ProfilePage = lazy(() => import("@pages/main/ProfilePage"));
const VerifyAccount = lazy(() => import("@pages/auth/VerifyaAccount"));
const ForgetPassword = lazy(() => import("@pages/auth/ForgetPassword"));
const ResetPassword = lazy(() => import("@pages/auth/ResetPassword"));
const UserDetail = lazy(() => import("@pages/auth/UserDetail"));
const TermsOfUsage = lazy(() => import("@pages/TermsOfUsage"));
const PrivacyPolicy = lazy(() => import("@pages/PrivacyPolicy"));
const About = lazy(() => import("@pages/About"));
const Payment = lazy(() => import("@pages/main/Payment")); // Lazy load Payment
const MainContainer = lazy(() => import("./Layout/MainContainer")); // Lazy load MainContainer

const Loading = () => (
  <div className="h-screen w-screen flex justify-center items-center flex-col gap-2">
    <img src={suppgramLogo} className="h-8 animate-pulse" />
  </div>
);

const PageTracker = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return <></>;
};

function App() {
  const { profile, user, session, setSession, setUser, loadCurrentProfile } =
    useAuthManger();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session || undefined);
        setUser(session?.user || undefined);
        if (session?.access_token)
          localStorage.setItem(ACCESS_TOKEN, session?.access_token);
        else {
          setLoading(false);
          localStorage.removeItem(ACCESS_TOKEN);
        }
      }
    );
    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user && !profile) {
      const userDetail = user.user_metadata.userDetail;

      if (userDetail) {
        loadCurrentProfile({
          variables: { id: user.user_metadata.userDetail },
        }).finally(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [user]);

  useLayoutEffect(() => {
    ReactGA.initialize("G-SB8P4LJMD");
  }, []);

  return (
    <>
      <TooltipProvider>
        <ToastProvider>
          {loading ? (
            <Loading />
          ) : (
            <BrowserRouter>
              <PageTracker />
              <Suspense fallback={<Loading />}>
                <Routes>
                  <Route path="/terms-of-use" element={<TermsOfUsage />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/about-us" element={<About />} />
                  <Route path="/payment/:paymentID" element={<Payment />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route
                    element={
                      <PublicRoute isAuthenticated={session != undefined} />
                    }
                  >
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgetPassword />}
                    />
                    <Route
                      path="/verify-email/:token"
                      element={<VerifyAccount />}
                    />
                  </Route>
                  <Route
                    element={
                      <PrivateRoute isAuthenticated={session != undefined} />
                    }
                  >
                    <Route
                      path="/reset-password/*"
                      element={<ResetPassword />}
                    />
                    <Route path="/userdetail" element={<UserDetail />} />
                  </Route>
                  <Route path="/" element={<MainContainer />}>
                    <Route path="/:postId" element={<HomePage />} />
                    <Route path="/shared/:postId" element={<div />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/profile/:userId" element={<ProfilePage />} />
                    <Route
                      index
                      element={
                        <Suspense fallback={<Loading />}>
                          <HomePage />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
              </Suspense>
            </BrowserRouter>
          )}
        </ToastProvider>
        <Toaster />
      </TooltipProvider>
    </>
  );
}

export default App;
